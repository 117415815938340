@use '@angular/material' as mat;
@include mat.core();

$my-primary: (
        100: #424CCD,
        500 : #424CCD,
        700 : #424CCD,
        contrast: (
                100: #ffffff,
                500 : #ffffff,
                700 : #ffffff,
        )
);
$my-accent: (
        100 : #1AD7B5,
        500 : #1AD7B5,
        700 : #1AD7B5,
        contrast: (
                100 : #ffffff,
                500 : #ffffff,
                700 : #ffffff,
        )
);

.mat-button {
  color:#424CCD;
}

$custom-theme-primary: mat.define-palette($my-primary, 500);
$custom-theme-accent: mat.define-palette($my-accent, 500);
$custom-theme-warn: mat.define-palette(mat.$red-palette, 500);
$custom-theme: mat.define-light-theme($custom-theme-primary, $custom-theme-accent, $custom-theme-warn);

body .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
body .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
body .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-width: 1px;
}
body .mat-input-element,
body .mat-form-field{
  font-size: 14px;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  font-size: 20px;
  top: 0 !important;
  .mat-form-field-outline-start, .mat-form-field-outline-end {
    width: 8px !important;
    border-radius: 4px 0 0 4px;
    background-color: #FFFFFF;
  }
  .mat-form-field-outline-gap {
    background-color: #FFFFFF;
  }
  .mat-form-field-outline-end {
    border-radius: 0 4px 4px 0;
  }
}
.header-select {
  .mat-form-field-outline-start, .mat-form-field-outline-end {
    background-color: #FFFFFF !important;
  }
  .mat-form-field-outline-gap {
    background-color: #FFFFFF !important;
  }
}
.mat-form-field-appearance-outline {
  &.mat-form-field-can-float.mat-form-field-should-float {
    .mat-form-field-label {
      transform: translateY(-1.19375em) scale(0.75) !important;
    }
  }
  .mat-form-field-infix {
    padding: 0.3em 14px;
    margin-bottom: .5em;
    border-top: 0.54375em solid transparent !important;

    .mat-form-field-label-wrapper .mat-form-field-label {
      margin-left: 12px !important;
      top: 1.38em !important;
    }
  }

  .mat-form-field-flex {
    padding: 0 !important;
    border-radius: 26px !important;
  }

  .mat-form-field-underline {
    &::before {
      /*background-color: #EDF4FA !important;*/
    }
    width: 84%;
    left: 0;
    right: 0;
    margin: auto;
  }
  .mat-form-field-suffix {
    right: 6px;
    top: 0;
    position: relative;
    button {
      /* issue on optimization correlation dates
      margin-top: -26px;
      position: absolute;*/
    }
  }

  input.mat-input-element {
    margin-top: -0.1em;
  }
}

.mat-form-field.mat-form-field-has-label {
  .mat-form-field-label-wrapper .mat-form-field-label {
    margin-left: 10px;
  }
}

.mat-form-field:not(.mat-form-field-has-label) {
  &.big-input {
    .mat-input-element {
      padding: 3px;
      font-size: 16px;
      font-family: sans-serif !important;
    }
    .mat-form-field-suffix {
      top: -2px;
    }
  }
  .mat-form-field-infix {
    padding: 0.3em 14px;
  }
  .mat-input-element {
    font-family: sans-serif !important;
    color: $color-main-dark-green;
  }
  .mat-form-field-infix {
    border-top-width: 4px;
  }
  .mat-form-field-suffix {
    right: 6px;
    position: relative;
    .mat-icon {
      font-size: 20px !important;
      width: 20px !important;
      height: 20px !important;
    }
    button {
      margin-top: -28px;
      position: absolute;
      right: 6px;
      top: 0;
    }
  }
  /*.mat-select-arrow-wrapper {
    vertical-align: bottom;
  }*/
}

.cdk-overlay-pane .mat-menu-panel {
  border-radius: 0;
}

.mat-drawer-content {
  background-color: #ffffff;
}
mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  /*background-color: #F8F8F8;*/
}


body {
  .mat-card {
    background: #FFFFFF;
    box-shadow: 0 4px 16px rgba(20, 40, 112, 0.1) !important;
    border-radius: 4px;
    padding: 32px 40px;

    .mat-card-header-text {
      margin: 0;
      .mat-card-title {
        margin: 0 0 24px;
        font-family: 'os700', sans-serif;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: $color-dark;
      }
    }
  }
  .mat-button-wrapper {
    font-family: 'os700',sans-serif;
    font-weight: 700;
  }
  .mat-flat-button {
    border-radius: 4px !important;
  }
  button.mat-accent {
    &:hover {
      /* Disabled button had orange bg color on hover */
      /*background: #FCC70E;*/
    }
  }
  .mat-flat-button.mat-primary:not(.mat-button-disabled):hover {
    background-color: #004f99;
  }
  .mat-stroked-button.mat-primary {
    border-color: #424CCD;
    border-radius: 4px !important;

  }
  .mat-stroked-button.mat-primary:hover {
    color: #424CCD;
    border-color: #424CCD;
    background-color: transparent;
  }
  .mat-stroked-button,
  .mat-flat-button{
    padding: 2px 12px;
  }
}
.mat-select-arrow-wrapper {
  transform: translateY(0) !important;
  .mat-select-arrow {
    border: none !important;
    background-image: url("../images/icons/select-icon.svg");
    width: 12px;
    height: 10px;
    margin: 0 4px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
textarea.double-row-height {
  height: 79px;
}

body .mat-slide-toggle {
  .mat-slide-toggle-bar {
    height: 24px;
    width: 49px;
    border-radius: 20px;

    .mat-slide-toggle-thumb {
      width: 24px;
      height: 24px;
    }
  }
}
body .mat-slide-toggle.mat-primary {
  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: transparent;
      border: 1px solid #424CCD;

      .mat-slide-toggle-thumb-container {
        transform: translate3d(24px, 0, 0);
      }
      .mat-slide-toggle-thumb {
        background-color: #424CCD;
      }
    }
  }
  .mat-slide-toggle-bar {
    background-color: transparent;
    border: 1px solid #CDD5E6;

    .mat-slide-toggle-thumb {
      background-color: #CDD5E6;
    }
  }
}
body .mat-slide-toggle-thumb-container {
  top: -1px;
  .mat-slide-toggle-thumb {
    box-shadow: none;
  }
}
.guide-tip {
  font-size: 18px !important;
}
.cim-toastr {
  background-color: #fff !important;
  color: #000 !important;
  margin-top: 80px !important;

  .mat-button-wrapper {
    color: #000 !important;
  }
}
.checkbox-wrapper {
  font-size: 16px;
  font-weight: 400;
}
.mat-form-field-wrapper {
  /*padding-bottom: 8px;*/ /* table header cell display issue */
}

/* Always show slider thumb label */
.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
}
.mat-slider-thumb {
  transform: scale(0) !important;
}
.mat-slider-thumb-label-text {
  opacity: 1 !important;
}

body .mat-stepper-horizontal,
body .mat-stepper-vertical {
  background-color: transparent;
}

$my-typography: mat.define-typography-config(
    $font-family: '"os100", "os400"'
);

@include mat.core-theme($custom-theme);
@include mat.all-component-themes($custom-theme);
@include mat.typography-hierarchy($my-typography);
