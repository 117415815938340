body {
  .mat-dialog-container {
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 7px 21px rgba(0, 0, 0, 0.02);
    position: relative;

    .dialog-close-btn {
      cursor: pointer;
      color: $color-grey;
      position: absolute;
      top: 10px;
      right: 10px;
      mat-icon {
        font-size: 20px;
        line-height: 20px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .cdk-overlay-dark-backdrop {
    background-color: $color-grey;
    opacity: .7 !important;
  }
}
