$color-main-verylightgrey: #F4F7F7;
$color-main-lightgreen: #DEE7E5;
$color-main-midgreen: #17676C;
$color-main-dark-green: #1F3A43;

$box-light-shadow: 0 3px 5px #ebecf0;
$box-border: 1px solid rgba(0, 0, 0, 0.125);;
$box-border-radius: 4px;
$primary-light-blue: #424ccd;

$primary-blue: #424ccd;
$color-mid-grey: #CDD5E6;
$color-dark: #0c0c68;
$color-grey: #1F212B;
$color-very-light-grey: #FAFAFA;

$color-dark-blue: #0c0c68;
$color-light-grey: #E6EAEF;
$color-dark-grey: #1F212B;
$color-light-grey-select: #F6F7FC;


$color-dark-table-header: #222C5F;
$color-dark-table-cell: #1B1A3E;
$color-dark-table-border: #2F3A76;


$cimalti-complementary: #0c0c68;
$cimalti-primary: #424ccd;
$cimalti-offblack: #1F212B;


$tablet: "(max-width: 992px)";
$phone: "(max-width: 768px)";
$mini: "(max-width: 600px)";
