@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

*:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
* {
  font-family: 'os400', sans-serif;
  font-weight: 400;
  color: $cimalti-complementary;
}

p {
  font-size: 16px;
  color: #3C3E63;
}
body button,
body .mat-button,
body .mat-raised-button,
body .mat-icon-button,
body .mat-stroked-button,
body .mat-flat-button,
body .mat-fab,
body .mat-mini-fab{
  font-size: 13px;
}
body .mat-button, body .mat-icon-button, body .mat-stroked-button, body .mat-flat-button {
  min-width: 40px;
}
body mark {
  background-color: #ffe350;
}
.navigation-button{
  color: #e6eaef;
}
a:hover {
  text-decoration: none !important;
}
body,html {
  height: 100%;
  background-color: #f0f0f0;
  font-size: 16px;
  line-height: 24px;
  font-family: 'os400', sans-serif;
  font-weight: 400;
}
.main-container {
  position: relative;
  padding-bottom: 20px;
  height: 100%;
  max-width: 1520px;

  router-outlet.main-router-outlet ~ * {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    padding: 70px 15px 0;
  }
}
.noti-card {
  display: flex !important;
  .noti-content {
    flex: 3;
    display: inline-block;
    line-height: 20px;
    padding-top: 4px;
    .noti-title {
      display: block;
      font-weight: bold;
      line-height: 20px;
    }
    .noti-content {
      display: block;
      line-height: 20px;
    }
  }
  .noti-actions {
    display: inline-block;
    text-align: right;
    .mat-icon {
      margin-right: 0 !important;
    }
  }
}
.divider-right {
  &::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 1px;
    background-color: #b4b4b4;
    position: absolute;
    top: 22px;
    right: -4px;
  }
}

.text-bold {
  font-family: 'os700', sans-serif !important;
  font-weight: 700;
}

.text-semi-bold {
  font-family: 'os600', sans-serif !important;
  font-weight: 600;
}
.text-normal {
  font-family: 'os400', sans-serif !important;
  font-weight: 400;
}
.text-small {
  font-size: 12px !important;
}
.text-grey {
  font-family: 'os400', sans-serif !important;
  font-weight: 400;
  color: $cimalti-offblack;
}

.text-400 > *{
  font-family: 'os400', sans-serif !important;
  font-weight: 400 !important;
}

body .mat-select-panel {
  border-radius: $box-border-radius !important;
}

body .mat-menu-panel {
  border-radius: $box-border-radius !important;
  max-width: 100% !important;
}

.divider-bottom {
  display: block;
  margin-bottom: 1.5rem !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.text-truncate {
  cursor: default;
}
h1 {
  font-family: 'os700', sans-serif !important;
  font-weight: 600;
  font-size: 18px;
  line-height:  normal;
  color: $cimalti-complementary;
}

.underscore {
  
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
}

.page-title {
  button {
    vertical-align: bottom;
    mat-icon {
      color: $cimalti-complementary;
    }
  }
}
.popover-info-btn mat-icon{
  color: $cimalti-complementary;
}
.btn-big {
  padding: 20px 58px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  /*font-family: 'NotoSans700', "Helvetica Neue", sans-serif !important;*/
}

.btn-wide {
  padding: 8px 58px !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

body .mat-toolbar {
  padding: 0;
  background-color: transparent;
  h1 {
    font-size: 18px;
    color: #000
  }
  margin-bottom: 8px;
  .toolbar-spacer {
    flex: 1 1 auto;
  }
  .mat-button-toggle-group {
    background-color: #ffffff;
    .mat-button-toggle-label-content {
      font-size: 15px;
      padding: 0 10px;
    }
    .mat-button-toggle-checked {
      color: #000000;
    }
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 36px;
  }
}
.mat-checkbox-layout {
  margin: 0;
}
body .mat-sort-header-content {
  text-align: left;
  padding-left: 5px;

}
.percent-header {
    margin-right: 15px;
  }
.section-title {
  color: #2E3970;
  font-family: "os600", sans-serif;
  font-weight: 600;
  font-size: 20px;
  margin: 20px 0;
}
body .card {
  overflow: hidden;
  border: none;
  border-radius: $box-border-radius;
  border-color: #D0D5EE;
  font-family: "os600", sans-serif;
  .card-header {
    border-radius: 4px 4px 0 0;
    background-color: transparent;
    font-weight: bold;
    border-bottom: none;

    color: #444666;
    padding: 1rem 1.25rem 0;
    font-size: 16px;
  }
  .card-sub-header {
    padding: 0 1.25rem 0;
    font-size: 13px;
    line-height: 18px;
    span {
      color: #3C3E63;
    }
  }
  .card-body {
    overflow: auto;
    padding: 0.5rem 1.25rem 1rem;
    thead.header-with-color {
      td {
        background-color: $color-light-grey;
        color: #3C3E63;
        font-weight: 400;
      }
    }
    thead td {
      &.bold-header {
        font-weight: bold;
        color: rgba(0, 0, 0, 0.42);
      }
      color: #12153E;
    }
    .table td {
      border-color: $color-light-grey;
      vertical-align: middle;
    }
    .table tr.selected-row {
      background-color: $color-light-grey-select;
    }
  }
}

.tab-nav {

  button {
    padding-right: 30px;
    .mat-button-wrapper {
      font-family: 'os400', sans-serif !important;
      font-weight: 400;
      .mat-icon{
        color: #e6eaef;
      }
    }

    &.active-section {
      .mat-button-wrapper {
        font-family: 'os700', sans-serif !important;
        font-weight: 700;
      }
      .active-dot {
        background-color: #1B1A3E;
      }
    }
  }

  .active-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: transparent;
  }


}

.add-image {
  height: 191px;
  width: 100%;
  max-width: 100%;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover, &:focus, &:active, &:visited {
    background-color: transparent;
  }
}
.cdk-overlay-container {
  z-index: 9999 !important;
}
.access-color-blue {
  color: #72AAFF;
}
.access-color-green {
  color: #35EAC9;
}
.access-color-yellow {
  color: #FFCA42;
}
.color-mid-grey {
  color: $color-mid-grey
}

.cim-input-label {
  color: $cimalti-offblack;
  font-size: 14px;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.ml-40 {
  margin-left: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.mw-240 {
  min-width: 240px;
}
.max-100 {
  max-width: 100px
}
.max-200 {
  max-width: 200px
}
.max-500 {
  max-width: 500px;
}
.min-height-600 {
  min-height: 630px;
}
.min-width-120 {
  min-width: 120px;
}
.summary-header {
  color: #2E3970;
  font-family: "os400", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.info-section {

  margin-top: 10px;
  font-size: 14px;
  line-height: 18px;
  .info-line{
    display: flex;
    justify-content: stretch;
    /*max-width: 360px;*/
    font-size: 14px;
    line-height: 18px;
    margin: 0 auto;
    padding: 2px;
    &:hover {
      background-color: #eeeeee;
      cursor: default;
    }
    .info-title{
      flex: 1;
      color: rgba(0, 0, 0, 0.54);
     
    }
    .info-value {
      flex: 1;
      padding-right: 2px;
      line-height: 20px;
      text-align: right;
      font-weight: 500;
      font-size: 14px;
      color: #12153E;
      font-family: 'osnumber400', monospace!important;
    }
  }
}

.mat-header-cell {
  font-size: 14px !important;
  &.text-right {
    .mat-sort-header-container {
      justify-content: flex-end;
    }
  }
}

.text-primary {
  color: $primary-blue !important;
  .mat-select-value {
    color: $primary-blue !important;
  }
}
.border-primary {
  .mat-form-field-outline-start,
  .mat-form-field-outline-end,
  .mat-form-field-outline-gap {
    border-color: $primary-blue !important;
  }
}
.select-primary {
  .mat-select-arrow {
    background-image: url("../images/icons/add-icon.svg") !important;
    width: 16px !important;
    height: 16px !important;
  }
}

app-asset-table-header-filter {
  vertical-align: top;
}

body .text-right {
  .mat-sort-header-content {
    text-align: right !important;
  }
}

/* Filtering menu */
.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  .search-field {
    flex: 1;
    margin: 0;
  }
  .filter-title {
    font-weight: bold;
    vertical-align: middle;
    margin-top: 6px;
  }
  .row {
    flex: 1;
  }
  .advanced-toggle {
    margin-left: 20px;
    margin-top: 10px;
  }
  .mat-form-field-wrapper {
    padding-bottom: 2px !important;
  }
}

.filter-menu {
  .mat-menu-item {
    height: 34px;
    line-height: 34px;

    .mat-checkbox {
      display: block;
    }
  }
}

body .description-tooltip {
  background: #ffffff;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  max-width: 400px;
  text-align: justify;
  padding: 10px;
  box-shadow: 0 4px 16px rgba(20, 40, 112, 0.1);
}
.etf-border-top {
  border-top: 2px solid #000000;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-02 {
  flex: 0.2;
}

.number-col * {
  font-family: 'osnumber400', monospace!important;
  color: $cimalti-complementary;
}

.company_name {
  font-family: 'os400', sans-serif;
  font-weight: 400;
  color: $cimalti-complementary;
}

td {
  font-family: 'osnumber400', sans-serif;
  color: $cimalti-complementary;
}

.fake-table {
  font-size: 14px;
}

.text-right {
  text-align: right;
  color: $cimalti-complementary;
}

.hidden {
  display: none;
}


.header-back {
  color: rgb(68,79,190);
  margin-top: 15px;
  svg {
    height: 15px!important;
    margin-right: 10px;
  }
}

.card-container {
  position: relative;
  max-width: 1200px;
  margin: auto;
  margin-top: 20px;
}