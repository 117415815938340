button, .btn {
  cursor: pointer;
}
button:focus, .btn:focus, a:focus {
  text-decoration: none !important;
}
body .btn {
  &.btn-light {
    background-color: #424CCD;
  }
  &.btn-shadow {
    box-shadow: $box-light-shadow;
  }
}
button.rounded-circle, .btn.rounded-circle {
  border: none;
}
.text-link {
  cursor: pointer;
}
.btn-white-rounded {
  padding: 6px 12px;
  text-decoration: none;
  color: #6A6A6A;
  background-color: #fff;
  border-radius: 20px;
  border: 2px solid transparent;
  &:hover {
    background-color: #ebebeb;
  }
  &.disabled {
    background-color: #b3babc;
    color: #000000;
    &:hover {
      background-color: #b3babc;
      cursor: default;
    }
  }
  .material-icons {
    font-size: 14px;
    vertical-align: text-bottom;
  }
}

.btn-black-rounded {
  padding: 6px 12px;
  text-decoration: none;
  color: #ffffff;
  background-color: #000000;
  border-radius: 20px;
  border: 2px solid white;
  &:hover {
    background-color: #000000;
  }
  &.disabled {
    &:hover {
      cursor: default;
    }
  }
}
button.mini-button {
  line-height: 30px;
  padding: 0 8px;
  font-size: 14px;
  min-width: 40px;
}
button.mini-icon-button {
  padding: 4px;
  margin: 0;
  min-width: 20px;
  line-height: 10px;
  mat-icon {
    width: auto;
    height: auto;
    font-size: 18px;
    text-align: center;
    vertical-align: text-bottom;
  }
  &.edit-button {
    color: $primary-blue;
    background-color: $primary-light-blue;

    &:hover,&.active {
      color: #ffffff;
      background-color: $primary-blue;
      cursor: pointer;
    }
  }
}
.mat-icon-button {
  &.active {
    color: $primary-blue;
  }
}
.btn-file {
  @extend .btn-light;

  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  overflow: hidden;

  &__image {
    @extend .img-fluid;
    max-height: 180px;
    display: block;
    margin: 0 auto;
  }

  [type=file] {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    font-size: 999px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: 0;
    background: #fff;
    cursor: inherit;
    display: block;
  }
}

.form-actions {
  margin-top: 3rem;
}

.is-active {
  background-color: rgba(255, 255, 255, 0.13)!important;
}
