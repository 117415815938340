@font-face {
  font-family: 'os100';
  src: url('../../fonts/jakarta/PlusJakartaSans-ExtraLight.ttf')  format('truetype');
  font-weight: normal;
  font-display: block;
}
@font-face {
  font-family: 'os200';
  src: url('../../fonts/jakarta/PlusJakartaSans-ExtraLight.ttf')  format('truetype');
  font-weight: normal;
  font-display: block;
}
@font-face {
  font-family: 'os300';
  src: url('../../fonts/jakarta/PlusJakartaSans-Light.ttf')  format('truetype');
  font-weight: normal;
  font-display: block;
}
@font-face {
  font-family: 'os400';
  src: url('../../fonts/jakarta/PlusJakartaSans-Regular.ttf')  format('truetype');
  font-weight: normal;
  font-display: block;
}
@font-face {
  font-family: 'os500';
  src: url('../../fonts/jakarta/PlusJakartaSans-Regular.ttf')  format('truetype');
  font-weight: 500;
  font-display: block;
}
@font-face {
  font-family: 'os600';
  src: url('../../fonts/jakarta/PlusJakartaSans-SemiBold.ttf')  format('truetype');
  font-weight: normal;
  font-display: block;
}
@font-face {
  font-family: 'os700';
  src: url('../../fonts/jakarta/PlusJakartaSans-Bold.ttf')  format('truetype');
  font-weight: bold;
  font-display: block;
}
@font-face {
  font-family: 'os800';
  src: url('../../fonts/jakarta/PlusJakartaSans-ExtraBold.ttf')  format('truetype');
  font-weight: bold;
  font-display: block;
}
@font-face {
  font-family: 'os900';
  src: url('../../fonts/jakarta/PlusJakartaSans-ExtraBold.ttf')  format('truetype');
  font-weight: bold;
  font-display: block;

}

@font-face {
  font-family: 'osnumber400';
  src: url('../../fonts/mono/DMMono-Regular.ttf')  format('truetype');
  font-weight: normal;
  font-display: block;
}
@font-face {
  font-family: 'osnumber800';
  src: url('../../fonts/mono/DMMono-Medium.ttf')  format('truetype');
  font-weight: bold;
  font-display: block;
}
