body .table th, body .table td {
  padding: 4px 8px;
  vertical-align: middle;
}
.table-no-word-wrap {
  white-space: nowrap;
}
.table {
  thead {
    .mat-form-field {
      margin-top: 0;
    }
  }
}
.table-header-align-top {
  thead {
    td {
      vertical-align: top !important;
    }
  }
}
.table-sticky-first-col {
  td .mat-icon,
  td .mat-icon-button{
    width: 20px;
    height: 20px;
    line-height: 18px;
    font-size: 18px;
  }
  td .mat-icon-button {
    min-width: 20px;
    margin-left: 4px;
  }
  td, th {
    border: none;
    height: 36px;
    line-height: 1;
  }
}
table td .mat-form-field.table-input-suffix {
  .mat-form-field-suffix {
    top: 0;
  }
}
.table-striped.table-sticky-first-col tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
  th:first-child, td:first-child {
    background-color: #f2f2f2;
  }
  td:nth-child(3), th:nth-child(3) {
    &::before {
      background-color: #f2f2f2;
    }
  }
}
.table-striped.table-sticky-last-col tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
  th:last-child, td:last-child {
    background-color: #f2f2f2;
  }
}
.table-striped.table-sticky-first-col tbody tr:nth-of-type(even) {
  background-color: #ffffff;
  th:first-child, td:first-child {
    background-color: #ffffff;
  }
  td:nth-child(3), th:nth-child(3) {
    &::before {
      background-color: #ffffff;
    }
  }
}
.table-striped.table-sticky-last-col tbody tr:nth-of-type(even) {
  background-color: #ffffff;
  th:last-child, td:last-child {
    background-color: #ffffff;
  }
}
.table-with-input {
  mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
    .mat-form-field-underline {
      bottom: 0.25em;
    }
  }
}

.table.table-sticky-header {
  thead {
    tr {
      position: sticky;
      top: 0;
      z-index: 2;
      td {
        background-color: #F4F4F8;
        /*z-index: 100;*/
      }
      td:first-of-type {
        z-index: 2;
      }
      td.shadow-col {
        background-color: transparent;
      }
      td:nth-child(2) {
        z-index: 1;
      }
      td:nth-child(3) {
        &::before{
          z-index: 1;
        }
      }
    }
  }
}

.table.table-sticky-first-col {
  th:first-child, td:first-child
  {
    background-color: #F4F4F8;
    position:sticky;
    z-index: 1;
    left:0;
    width: 250px;
    max-width: 250px;
    text-overflow: ellipsis;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 30px;
    }
  }
  td:nth-child(2) {
    width: 10px;
    height: 38px;
    position: sticky;
    left: 250px;
    box-shadow: inset 8px 0 6px -8px rgba(0,0,0,0.7);
  }
  td:nth-child(3) {
    &::before {
      display: block;
      width: 20px;
      /*height: 38px;*/
      height: 100%;
      content: '';
      position: absolute;
      left: -16px;
      top: 0;
      background-color: #ffffff;
    }
    padding-left: 20px;
    position: relative;
  }
  &.wider-first-col {
    th:first-child, td:first-child{
      width: 400px;
      max-width: 400px;
    }
    td:nth-child(2) {
      left: 400px;
    }
  }
}
body .card .table.table-hover tbody tr:hover {
  td, th, td::before {
    background-color: #dbdbdb !important;
  }
}
.table.table-sticky-last-col {
  th:last-child, td:last-child
  {
    background-color: #ffffff;
    position:sticky;
    right:0;
  }
}
td.checkbox-cell .mat-checkbox{
  &.mat-checkbox-checked {
    opacity: 1 !important;
  }
  opacity: 0;
}
tr:hover {
  .checkbox-cell .mat-checkbox{
    opacity: 1 !important;
  }
  .underline-on-hover {
    text-decoration: underline !important;
    &:hover {
      color: $primary-blue;
      cursor: pointer;
    }
  }
}
.table-cim {
  th, td {
    border: none;
    padding: 4px 0;
    font-size: 16px;
  }
  th.min-w-200 {
    min-width: 200px !important;
  }
  &.table-cim-padding {
    th, td {
      padding: 4px;
    }
  }

  &.table-cim-fixed {
    table-layout: fixed;
  }

  &.table-cim-small {
    th, td {
      font-size: 14px;
    }
  }
  &.table-cim-performance {
    th {
      width: 60px;
    }
    th:first-child {
      width: 200px;
    }
  }
  margin-bottom: 0;
}

.table th, .table td {
  padding: 8px;
  font-size: 14px;
}

/* Datatable style */
.table-container {
  position: relative;
  width: 100%;
  overflow: auto;
  td.mat-cell, td.mat-footer-cell, th.mat-header-cell {
    white-space: nowrap;
    padding-right: 20px;
  }
  td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type, th.mat-header-cell:last-of-type {
    padding-right: 10px;
  }
}

.table-manage-buttons {
  margin: 20px 20px 0;
  z-index: 100;

  .mat-table-filter {
    float: right;
    width: 100%;
    max-width: 200px;
  }
  .dropdown {
    display: inline-block;
    max-width: 200px;
    margin-left: 20px;
  }
}
.mat-table {
  tr td {
    vertical-align: middle;
  }
  .company-logo {
    max-width: 50px;
    max-height: 50px;
  }
  .user-image {
    width: 30px;
    height: 30px;
  }
  .user-name {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-top: 1px;
  }

  &.table-row-spacing {
    border-collapse: separate;
    background-color: transparent;

    tr.mat-row {
      background-color: transparent;

      &.example-detail-row {
        height: auto;
      }
      /*height: 80px;*/

      td.mat-cell {
        border-top: 20px solid transparent;
        padding: 0;
        background-color: transparent;

        .mat-cell-content-wrapper {
          background-color: #ffffff;
          padding: 16px 20px;

          box-sizing: border-box;
          border-radius: 4px;
          border: 1px solid #EFF2F9;

          .cell-title {
            width: 50%;
            overflow: hidden;
            font-size: 20px;
            line-height: 32px;
            color: $color-dark-grey;

            .project-title {
              font-size: 16px;
              line-height: 24px;
              color: $color-main-dark-green;
              font-family: 'NotoSans700', "Helvetica Neue", sans-serif !important;
            }
            .thumbnail {
              background-size: cover;
              background-position: center center;
              width: 60px;
              height: 60px;
              border-radius: 16px;
              display: inline-block;
              margin-right: 20px;
              vertical-align: middle;
            }
          }
        }

        .mat-cell-expanding-content {
          border: 1px solid #EFF2F9;
          border-top: none;
          background-color: #ffffff;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;

          &.expanded-content {
            border: 1px solid #809C9E;
            border-top: none;
            box-shadow: 0px 4px 8px rgba(23, 103, 108, 0.15);
          }

          .info-text {
            margin-left: 20px;
            color: #666666;
            font-size: 16px;
            font-weight: bold;
          }

          .subtitle {
            font-weight: bold;
            color: #464646;
            font-size: 14px;
          }
        }
      }
      td.mat-column-expandedDetail {
        border:none;
      }

      &.example-expanded-row {
        td.mat-cell {
          .mat-cell-content-wrapper {
            border-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    /* bootstrap table inside */
    .table {
      td {

        mat-icon {
          vertical-align: middle;
          font-size: 20px;
          line-height: 20px;
        }
        .mat-icon-button {
          width: 24px;
          height: 24px;
          line-height: 24px;
          mat-icon {
            line-height: 22px;
            font-size: 22px;
          }
        }
        color: $color-dark-grey;
      }
      thead {
        tr {
          th {
            color: $color-grey;
            font-size: 14px;
            font-family: 'os400', sans-serif;
            font-weight: 400;
          }
        }
      }
      tr:hover {
        td {
          background-color: $color-very-light-grey;
        }
      }
    }
  }
  tr.example-detail-row {
    height: 0;
  }

  .example-element-row td {
    border-bottom-width: 0;
  }

  .example-element-detail {
    overflow: hidden;
  }
}

th.mat-sort-header-sorted {
  color: black;
}
body .mat-paginator {
  background: transparent;
}
.mat-paginator-container {
  .mat-paginator-page-size-select {
    min-width: 74px;
  }
}
.is-inactive-row td {
  background-color: rgba(255, 0, 0, .1);
}
.mat-table.mat-small-table {
  td {
    padding: 0 4px;
  }
  tr.mat-row, tr.mat-header-row {
    height: 32px;
  }
  tr.mat-highlighted-row {
    background-color: #efefef;
    border: 2px solid #999999;
    th {
      color: #000000;
      font-weight: bold;
    }
  }
  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left: 8px;
  }
  th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
    padding-right: 8px;
  }
  tr.mat-footer-row {
    height: 32px;
    td {
      font-weight: 700 !important;
      font-family: 'os700', sans-serif !important;
    }
  }
}
