/* Dropdown */
body .dropdown-header, body .dropdown-item {
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
}
.btn.dropdown-toggle {
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  text-align: left;
  position: relative;
  margin-right: 1em;
}
.btn.dropdown-toggle::after {
  float: right;
  margin-top: 0.6em;
}
.dropdown.open .dropdown-menu {
  display: block;
  max-width: 400px;
  min-width: 320px;
}
.dropdown.open .dropdown-menu.dropdown-menu-right {
  right: 0;
  left: auto;
}
.dropdown .dropdown-menu .dropdown-list {
  max-height: 300px;
  overflow-y: auto;
}
.dropdown .dropdown-menu .empty-search-placeholder {
  font-size: 12px;
  color: #ccc;
  text-align: center;
  display: block;
  font-style: italic;
}

.user-company-filter {
  width: 180px;
}
.mat-select-filter-reset-btn {
  font-size: 16px;
}

body .mat-select-panel {
  max-height: 400px;

  .mat-option {
    font-size: 14px;
  }
}
body .card-menu-toggle {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #666;
  z-index: 500;
}
.mat-expansion-panel-content {
  height: 0;
  visibility: hidden;
}
.mat-expansion-panel.mat-expanded {
  .mat-expansion-panel-content {
    height: auto;
    visibility: visible;
  }
}
.mat-menu-panel.noti-menu {
  max-width: 310px;
}
body .mat-expansion-panel-header {
  padding: 0 15px;
  height: 48px;

  .mat-content {
    align-items: center;
  }
}
body .mat-expansion-panel-body {
  padding: 0 10px;
  font-size: 13px;

  .list-group-item{
    padding: 10px 2px;

    span {
      word-wrap: break-word;
    }
    i {
      padding-right: 4px;
      font-size: 16px;
      vertical-align: text-bottom;
    }
  }
}
.popover {
  min-width: 300px;
  .popover-body {
    border-radius: 0.3rem;
    padding: 0 5px;

    .list-group-item {
      padding: 0.75rem;
    }
  }
}
.popover-content {
  .pop-level-desc {
    font-size: 14px;
  }
}
