/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap";
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "assets/scss/index.scss";
@import "assets/scss/material.theme.scss";
@import "assets/scss/highchart-cimalti.scss";


.cursor-pointer {
  cursor: pointer !important;
}
body .mat-form-field {
  line-height: 1.225;
}




/* Stepper design */

@mixin styleStepLine($index) {
  .mat-horizontal-stepper-header {
    &+.mat-stepper-horizontal-line:nth-child(#{$index}) {
      height: 2px !important;
      background-image: linear-gradient(to right, #1B1A3E, #1B1A3E) !important;
    }
  }
}

.last-edited-step-1 {
  @include styleStepLine(2);
}

.last-edited-step-2 {
  @include styleStepLine(2);
  @include styleStepLine(4);
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before {width: 0!important;}

.mat-step-header .mat-step-header-ripple {display: none;}
.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: #fff;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
  padding: 0 !important;
  width: 28px !important;
}
.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 14px !important;
}
.mat-step-icon {
  border-radius: 4px !important;
  height: 28px !important;
  width: 28px !important;
}
.header-stepper .mat-step-label {
  display: none !important;
}
.mat-horizontal-stepper-header-container {
  margin-bottom: 20px;
}
.mat-step-header .mat-step-icon {
  background-color: #D0D5EE;
}
.mat-step-header .mat-step-icon-state-edit,
.mat-step-header .mat-step-icon-selected {
  background-color: #1B1A3E !important;
}
.content-stepper {
  padding-bottom: 20px;
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
}

